import React, { useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Badge, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import { ROUTE_HOME , ROUTE_GENERATOR_TICKET,ROUTE_GENERATOR_DETAIL, ROUTE_LOGIN, ROUTE_GENERATOR_CANCELED, ROUTE_GENERATOR_NOT_ISSUED, ROUTE_GENERATOR_PROCESSED_CANCELED, ROUTE_IP_PERMISED, ROUTE_IP_DENIED} from '@/toolbox/constants/route-map';
import { DrawerComponent } from './Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import { readLocalStorage, removeLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_PARMS, KEY_TOOGLE_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { authenticationService } from '@/service/services/Authentication.service';

type Props = {
}

export const Header: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const history= useHistory();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const isMenuOpen = Boolean(anchorEl);

   const [loading, setLoading] = useState<any>()

   const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleMenuClose = () => {
      setAnchorEl(null);
   };

   const renderMenu = (
      <Menu
         id="fade-menu"
         MenuListProps={{
            'aria-labelledby': 'fade-button',
         }}
         anchorEl={anchorEl}
         open={isMenuOpen}
         onClose={handleMenuClose}
         TransitionComponent={Fade}
      >
          <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_DETAIL}>
          IP permitidas
         </MenuItem>
         <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_NOT_ISSUED}>
         IP Denegadas
         </MenuItem>
         <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_PROCESSED_CANCELED}>
         Registrar
         </MenuItem>
      </Menu>
   );
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const handleLogout = () => {
     removeLocalStorage(KEY_PARMS);
     removeLocalStorage(KEY_TOOGLE_MENU)
     removeLocalStorage(KEY_USER_DATA);
     localStorage.removeItem('dataUser');
     window.location.replace('/');
   }
   return (
      <div>
         <Box sx={{ flexGrow: 1, padding: 4 }}>
            <AppBar>
               <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', bgcolor:'#176ECA' }}>
                  <Grid>
                     <Button color="inherit" component={Link} to={ROUTE_GENERATOR_TICKET}>
                        <Typography
                           variant="h6"
                           noWrap
                           component="div"
                           sx={{ display: { xs: 'block', sm: 'block' } }}
                        >
                           CONTROL DE IP's
                        </Typography>
                     </Button>
                  </Grid>
                  {
                     isMobile ? (
                        <DrawerComponent />
                     ) : (
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} >
                           <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
                              <Button sx={{bgcolor:'#f7ac33',ml:1}} variant='contained' component={Link} to={ROUTE_IP_PERMISED}>
                              IP PERMITIDAS
                              </Button>
                              <Button color="inherit" sx={{bgcolor:'#C62828',ml:1}} component={Link} to={ROUTE_IP_DENIED}>
                              IP DENEGADAS
                              </Button>
                              <Button color="inherit" onClick={()=>handleLogout()}>
                                Cerrar Sesion
                              </Button>
                           </Box>
                        </Box>
                     )
                  }
                   {renderMenu}
               </Toolbar>
            </AppBar>
         </Box>
      </div>
   );
}

import React, {  useEffect, FunctionComponent, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, LinearProgress, MenuItem, Select, Snackbar, Stack, TableContainer, Typography } from '@mui/material';
import { saleService } from '@service/services/Sale.service';
import { doopyService } from '@service/services/Doopy.service'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_ARRAY_MY_MENU, KEY_EMPRESA, KEY_LOCAL_STORAGE, KEY_PARMS, KEY_TIPO_ROL, KEY_TOKEN, KEY_TOOGLE_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Protected } from '@/components/layout/Protected';
import { FormatDouble, moneyFormat, moneyFormatInt } from '@/toolbox/helpers/money.helper'
import { useMediaQuery, useTheme } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export const IpDenied: FunctionComponent = (props: any) => {

   const [data, setData] = useState<any>([]);
   const [disabled, setDisabled] = useState(false)
   const [filter, setFilter] = useState(null);
   const [open, setOpen] = useState(false);
   const componentRef = useRef();
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   // Modificar acá y traer Data dinámica
   useEffect(() => {
      getIpDenied();
      setFilter(0)
   }, [])


   async function getIpDenied(){
      setOpen(true)

      let tipoRol= readLocalStorage(KEY_TIPO_ROL);

      if(tipoRol==3){
         const resp = await doopyService.ipDeniedSA()
         const dataIp = resp.resp
         console.log(dataIp)
         setData(dataIp)
         console.log(data)
         setOpen(false)
      }else{
         const resp = await doopyService.ipDenied()
         const dataIp = resp.resp
         console.log(dataIp)
         setData(dataIp)
         console.log(data)
         setOpen(false)
      }

   }

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         // backgroundColor: theme.palette.common.black,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: { fontSize: 14 },
   }));

   const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
      // hide last border
      '&:last-child td, &:last-child th': { border: 0 },
   }));


   const HabilitarData = async (i) => {
     setDisabled(true)

      const datos = data[i];
      const ip = datos.ip;
      const nick = datos.nick;
      console.log(datos)


      let tipoRol= readLocalStorage(KEY_TIPO_ROL);

      if(tipoRol == 3){

         const resp = await doopyService.permisedIpDeniedSA(ip, nick);
         console.log(resp)
         getIpDenied();
         console.log(datos)
         setDisabled(false)

      }else{
         const resp = await doopyService.permisedIpDenied(ip, nick);
         console.log(resp)
         getIpDenied();
         console.log(datos)
         setDisabled(false)
      }
   }


   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <CircularProgress color="inherit" />
         </Backdrop>

     <div className='col-xs-12 col-md-offset-3 text-dark' style={{ padding: 40, textAlign: "center", minHeight: "100vh" }}>
         <Grid container justifyContent='space-between' mb={4}>
            <Grid item>
               {isMobile?(
                  <Grid container justifyContent='center'>
                  <Typography variant='h4'>IP's DENEGADAS</Typography>
                  </Grid>
               ):(
                  <Typography variant='h3'>IP's DENEGADAS</Typography>
               )}

            </Grid>
            {isMobile?
            (<></>):
            (<Grid item pt={2}>
               <Button variant="contained" color="success" onClick={()=>getIpDenied()} >
                  Actualizar
               </Button>
            </Grid>)
            }
         </Grid>

         <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small'>
               <TableHead sx={{bgcolor:'#176ECA', color:'#fff'}}>
                  <TableRow >
                     {isMobile?(
                     <>
                     <TableCell sx={{color:'#fff', position:'sticky', left:0, background:'#176ECA'}}></TableCell>
                     <TableCell align="left" sx={{color:'#fff'}} >USUARIO</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>RUT</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>IP</TableCell>
                   </>
                     )
                     :(
                     <>
                     <TableCell align="left" sx={{color:'#fff'}} >USUARIO</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>RUT</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>IP</TableCell>
                     <TableCell align="center" sx={{color:'#fff',minWidth:{xs:'2rem',lg:'5rem'}}}>ACCIONES</TableCell>
                     </>
                     )}

                  </TableRow>
               </TableHead>
               <TableBody>
                  {data.map((row, i) => {

                    return(
                     <StyledTableRow key={i}>
                        {isMobile?(
                                 <>
                                     <StyledTableCell align="left" sx={{position:'sticky', left:0, background: i%2==0?'#f3f3f3':'#fff' }}>
                                         <IconButton sx={{bgcolor:'white',mb:1}} disabled={disabled} onClick={() => {HabilitarData(i) }}>
                                            <PersonAddIcon  color='primary'/>
                                         </IconButton>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{row.nick}</StyledTableCell>
                                      <StyledTableCell align="left">
                                      {row.rut}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                      {row.ip}
                                         </StyledTableCell>
                                 </>
                        ):
                        (
                           <>
                              <StyledTableCell align="left">
                              {row.nick}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                              {row.rut}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                              {row.ip}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                    <Button variant="contained" disabled={disabled} onClick={() => { HabilitarData(i) }} color="secondary" sx={{ mr: 2 }}>
                                       Habilitar
                                    </Button>
                              </StyledTableCell>
                           </>
                        )}
                  </StyledTableRow>
                    )
})}
               </TableBody>
            </Table>
         </TableContainer>
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
         <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
         >
            {snackBarConfig.message}
         </Alert>
      </Snackbar>

      </div>
      </Protected>

   )
}

